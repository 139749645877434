/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 2, 2017 */
@font-face {
    font-family: 'ubuntulight';
    src: url('../fonts/ubuntu-l-webfont.eot');
    src: url('../fonts/ubuntu-l-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ubuntu-l-webfont.woff2') format('woff2'),
         url('../fonts/ubuntu-l-webfont.woff') format('woff'),
         url('../fonts/ubuntu-l-webfont.ttf') format('truetype'),
         url('../fonts/ubuntu-l-webfont.svg#ubuntulight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ubuntumedium';
    src: url('../fonts/ubuntu-m-webfont.eot');
    src: url('../fonts/ubuntu-m-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ubuntu-m-webfont.woff2') format('woff2'),
         url('../fonts/ubuntu-m-webfont.woff') format('woff'),
         url('../fonts/ubuntu-m-webfont.ttf') format('truetype'),
         url('../fonts/ubuntu-m-webfont.svg#ubuntumedium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ubunturegular';
    src: url('../fonts/ubuntu-r-webfont.eot');
    src: url('../fonts/ubuntu-r-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ubuntu-r-webfont.woff2') format('woff2'),
         url('../fonts/ubuntu-r-webfont.woff') format('woff'),
         url('../fonts/ubuntu-r-webfont.ttf') format('truetype'),
         url('../fonts/ubuntu-r-webfont.svg#ubunturegular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ubuntubold';
    src: url('../fonts/ubuntu-b-webfont.eot');
    src: url('../fonts/ubuntu-b-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ubuntu-b-webfont.woff2') format('woff2'),
         url('../fonts/ubuntu-b-webfont.woff') format('woff'),
         url('../fonts/ubuntu-b-webfont.ttf') format('truetype'),
         url('../fonts/ubuntu-b-webfont.svg#ubuntubold') format('svg');
    font-weight: normal;
    font-style: normal;
}
$regular: 'ubunturegular';
$bold: 'ubuntubold';
$italic: 'ubuntulight';
$medium: 'ubuntumedium';


@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}
*:after, *:before {
	line-height: 1;
}
body {
	display: flex;
	height: 100vh;
	position: relative;
	flex-direction: column;
	font-family: $regular;
	color: #272727;
	font-size: 14px;
	margin: 0;
}

h1 {
	display: block;
	font-family: $regular;
	font-size: 36px;
	text-transform: uppercase;
	color: #272727;
	margin-bottom: 25px;
	line-height: 1;
	@media screen and (max-width: 991px) {
		font-size: 30px;
		margin-bottom: 15px;
	}
	@media screen and (max-width: 768px) {
		font-size: 26px;
	}
	@media screen and (max-width: 480px) {
		font-size: 22px;
	}
}
h2 {
	display: block;
	font-family: $bold;
	font-size: 24px;
	color: #272727;
	margin-top: 0px;
	margin-bottom: 20px;
	a {
		font-size: 24px!important;
		font-family: $bold;
		color: #272727;
		display: block;
	}
	&.h2 {
		position: relative;
		text-transform: uppercase;
		padding-bottom: 25px;
		margin-bottom: 35px;
		&:after {
			content: '';
			display: block;
			height: 3px;
			width: 100px;
			background-color: #3f9725;
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}
	@media screen and (max-width: 991px) {
		font-size: 22px;
		a {
			font-size: 22px!important;
		}
	}
	@media screen and (max-width: 768px) {
		font-size: 20px;
		a {
			font-size: 20px!important;
		}
	}
}
h3 {
	font-family: $bold;
	font-size: 22px;
	color: #272727;
	@media screen and (max-width: 991px) {
		font-size: 20px;
	}
}
h4 {
	font-family: $bold;
	font-size: 20px;
	text-transform: uppercase;
	color: #272727;
	@media screen and (max-width: 991px) {
		font-size: 18px;
	}
}
h5 {
	font-family: $bold;
	font-size: 18px;
	color: #272727;
	margin: 15px 0;
	@media screen and (max-width: 991px) {
		font-size: 16px;
	}
}
h6 {
	font-family: $bold;
	font-size: 16px;
	text-transform: uppercase;
	color: #272727;
	@media screen and (max-width: 991px) {
		font-size: 14px;
	}
}
p {
	line-height: 1.5;
}
a {
	color: #272727;
	transition: 350ms;
	text-decoration: none;
	&:focus {
		color: #272727;
		text-decoration: none;
	}
	&:hover {
		color: #5cbe36;
		text-decoration: none;
	}
}
input, textarea, button {
	border-radius: 0px;
	-webkit-appearance: none;
}
strong {
	font-family: $bold;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
	font-size: 14px;
	background-color: rgba(0,0,0,0.5);
	textarea, input {
		font-family: $regular;
		font-size: 14px;
		background-color: #fff;
		padding: 10px 15px;
	}
	a,p {
		font-size: 14px;
		text-decoration: none;
	}
}
.main {
	background-color: rgba(0,0,0,0.8);
	color: #fff;
	padding-top: 153px;	
	.container {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	@media screen and (max-width: 1250px) {
		padding-top: 130px;
	}
	@media screen and (max-width: 1170px) {
		.container { 
			padding-top: 25px;
			padding-bottom: 25px;
		}
	}
	@media screen and (max-width: 768px) {
		padding-top: 100px;	
		.container { 
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
}
.container {
	width: 1250px;
	margin: 0 auto;
	@media screen and (max-width: 1250px) {
		width: 100%;
		padding: 0px 15px;
		box-sizing: border-box;
	}
}
.btn {
	display: table;
	width: auto!important;
	background-color: #3f9725!important;
	font-family: $bold!important;
	font-size: 14px!important;
	color: #fff!important;
	text-transform: uppercase;
	text-decoration: none!important;
	text-align: center;
	cursor: pointer;
	border-radius: 0px;
	border: 3px solid #3f9725;
	padding: 10px 30px 8px!important;
	transition: 350ms;
	box-sizing: border-box;
	line-height: 1;
	&:focus, &:active {
		color: #fff;
		background-color: #3f9725!important;
	}
	&:hover, &.active {
		color: #fff!important;
		opacity: 0.7;
	}	
	@media screen and (max-width: 768px) {
		font-size: 16px;
	}
}
.gradient {
	background: linear-gradient(to bottom, #60c339, #429922 60%)!important;
	border: none!important;
	&.btn {
		&:hover {
			background: linear-gradient(to bottom, #429922, #60c339 60%)!important;
			border: none!important;
			color: #fff!important;
			opacity: 1;
		}
	}
}

.check--list {
	@include ul-default;
	li {
		display: block;
		padding-left: 22px;
		position: relative;
		margin-bottom: 5px;
		font-size: 14px;
		&:before {
			content: '\f00c';
			font-size: 14px;
			font-family: 'fontAwesome';
			position: absolute;
			left: 0;
			top: 0;
			color: #3f9725;
		}
	}
}
.check--circle--list {
	display: block;
	font-size: 14px;
	@include ul-default;
	@include inline-block;
	li {
		position: relative;
		padding: 5px 10px 5px 40px;
		margin-bottom: 10px;
		span {
			display: block;
			width: 28px;
			height: 28px;
			border-radius: 100%;
			background-color: #3f9725;
			font-family: $bold;
			font-size: 18px;
			color: #fff;
			text-align: center;
			box-sizing: border-box;
			padding-top: 3px;
			position: absolute;
			left: 0;
			top: 0px;
		}
	}
	@media screen and (max-width: 768px) {
		li {
			padding: 0px 5px 5px 35px;
			span {
				width: 24px;
				height: 24px;
				font-size: 16px;
			}
		}
	}
}

.bg {
	display: block;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: -1;
	img {
		display: block;
		width: 100%;
		height: auto;
	}
}

/* header */
header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	color: #fff;
	border-top: 4px solid #3f9725;
	border-bottom: 4px solid #3f9725;
	background-color: rgba(0,0,0,0.8);
	z-index: 9;
	.container {
		position: relative;
	}
	.right {
		width: 100%;
		padding-left: 215px;
		box-sizing: border-box;
		text-align: right;
		@include inline-block;
	}
	.row.gradient {
		margin-top: 22px;
		text-align: left;
		&:hover {
			background: linear-gradient(to bottom, #60c339, #429922 60%)!important;
		}
	}
	ul {
		@include ul-default;
	}

	/* header logo */
	.logo {
		width: 215px;
		height: 153px;
		background-color: #fff;
		position: absolute;
		left: 0;
		top: 0px;
		overflow: hidden;
		> img {
			display: block;
			width: 250px;
			margin: 10px 0px 0px -20px;
		}
		span {
			display: block;
			position: absolute;
			bottom: 10px;
			left: 0;
			width: 220px;
			img {
				display: block;
				width: 100%;
			}
		}
	}
	/* header logo */

	/* header location */
	.location {
		width: 250px;
		position: relative;
		padding-left: 58px;
		text-align: left;
		margin: 35px 75px 0px 0px;
		@include inline-block;
		i {
			display: block;
			width: 34px;
			height: 34px;
			position: absolute;
			left: 0;
			top: 0;
			text-align: center;
			font-size: 20px;
			padding-top: 6px;
			box-sizing: border-box;
		}
	}
	.location--btn {
		display: none;
	}
	/* header location */

	/* header phones */
	.phones {
		width: 160px;
		position: relative;
		padding-left: 58px;
		text-align: left;
		margin: 35px 75px 0px 0px;
		@include inline-block;
		i {
			display: block;
			width: 34px;
			height: 34px;
			position: absolute;
			left: 0;
			top: 0;
			text-align: center;
			font-size: 20px;
			padding-top: 7px;
			box-sizing: border-box;
		}
		li {
			display: block;
			line-height: 1.2;
			a {
				display: block;
				font-family: $bold;
				font-size: 18px;
				color: #fff;
				text-decoration: none;
			}
		}
	}
	.phones--btn {
		display: none;
	}
	/* header phones */

	/* header application btn */
	.btn {
		margin-top: 35px;
		font-size: 13px!important;
		text-transform: uppercase;
		@include inline-block;
	}
	/* header application btn */

	/* header nav */
	.nav {
		padding-left: 25px;
		@include inline-block;
		> ul {
			> li {
				@include inline-block;
				> a {
					display: block;
					position: relative;
					font-size: 13px;
					font-family: $bold;
					color: #fff;
					text-transform: uppercase;
					text-decoration: none;
					padding: 18px 15px;
					&:hover {
						text-shadow: 2px 2px 2px rgba(0,0,0,0.6);
					}
				}
				&.submenu {
					> a {
						padding-right: 35px;
						&:after {
							content: '\f107';
							position: absolute;
							right: 12px;
							top: 15px;
							font-family: 'fontAwesome';
							font-size: 18px;
							color: #fff;
						}	
					}
				}
				&.active > a {
					text-shadow: 2px 2px 2px rgba(0,0,0,0.6);
				}
				.submenu__block {
					display: none;
					width: 1250px;
					position: absolute;
					left: 0;
					top: 149px;
					right: 0;
					background: #fff;
					border: 4px solid #5ebf37;
					box-sizing: border-box;
					padding: 50px 15px;
					font-size: 0;
					z-index: 1;
					.submenu__block__col {
						width: 33.33%;
						font-size: 11px;
						text-transform: uppercase;
						position: relative;
						box-sizing: border-box;
						padding: 0 15px 0 70px;
						margin-bottom: 15px;
						@include inline-block;
						.submenu__block__col__img {
							width: 50px;
							overflow: hidden;
							position: absolute;
							top: 0;
							left: 0;
							img {
								display: block;
								max-width: 100%;
							}
						}
						.submenu__block__col__title {
							display: block;
							font-family: $bold;
							font-size: 18px;
							text-transform: uppercase;
							color: #272727;
							margin-bottom: 10px;
						}
						ul li {
							position: relative;
							padding: 0 10px 0px 20px;
							border-top: 1px solid #ebe8e8;
							&:after {
								content: '';
								display: block;
								width: 6px;
								height: 6px;
								position: absolute;
								background-color: #bcbcbc;
								left: 5px;
								top: 8px;
							}
							&:last-of-type {
								border-bottom: 1px solid #ebe8e8;
							}
							a {
								display: block;
								padding: 5px 0px;
							}
							&.active > a {
								color: #5ebf37;
							}
						}
					}
				}
			}
		}
	}
	.nav--btn {
		display: none;
	}
	/* header nav */

	/* header search */
	.search {
		width: 230px;
		height: 41px;
		position: relative;
		top: 0px;
		float: right;
		background-color: #fff;
		border: 1px solid #fff;
		padding-right: 41px;
		box-sizing: border-box;
		overflow: hidden;
		margin: 6px;
		@include inline-block;
		input[type="text"] {
			width: 100%;
			padding: 12px 10px 13px;
			border: none;
			box-sizing: border-box;
			outline: none;
		}
		button {
			height: 41px;
			width: 41px;
			position: absolute;
			right: 0;
			top: 0;
			box-sizing: border-box;
			cursor: pointer;
			&:after {
				content: '\f002';
				display: block;
				font-family: 'fontAwesome';
				font-size: 20px;
				color: #fff;
				position: absolute;
				left: 0;
				right: 0;
				top: 10px;
				text-align: center;
			}
			&:hover {
				background: linear-gradient(to bottom, #429922, #60c339 60%)!important;
			}
		}
	}
	.search--btn {
		display: none;
	}
	/* header search */
	@media screen and (max-width: 1250px) {
		background-color:  #fff;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 9;
		.row.gradient {
			margin-top: 7px;
		}
		.logo {
			width: 150px;
			height: 103px;
			left: 15px;
			> img {
				width: 180px;
				margin: 5px 0 0 -15px;
			}
			span {
				width: 175px;
				bottom: 5px;
				margin-left: -15px;
			}
		}
		.nav {
			padding-left: 10px;
			> ul > li {
				> a {
					padding: 18px 13px;
				}
				.submenu__block {
					width: 100%;
					top: 103px;
				}
			}
		}
		.right {
			padding-left: 150px;
		}
		.location {
			padding-left: 40px;
			margin-top: 8px;
			color: #272727;
			i {
				color: #fff;
			}
		}
		.phones {
			padding: 2px 0px 0px 40px;
			margin-top: 8px;
			li a {
				font-size: 14px;
				color: #272727;
			}
		}
		.search {
			margin-top: 7px;
		}
		.btn {
			margin-top: 9px;
		}
	}
	@media screen and (max-width: 1170px) {
		.row.gradient {
			margin: 0 -15px;
			min-height: 42px;
		}
		.logo {
			width: 118px;
			height: 80px;
			> img {
				width: 110px;
				margin-left: 2px;
			}
			span {
				width: 135px;
				margin-left: -10px;
			}
		}
		.right {
			padding-left: 0px;
		}
		.phones {
			margin: 4px 25px 0 0;
			i {
				height: 30px;
				width: 30px;
				padding-top: 5px;
			}
		}
		.location {
			margin: 4px 25px 0 0;
			i {
				height: 30px;
				width: 30px;
				padding-top: 4px;
			}
		}
		.btn {
			margin-top: 4px;
			padding: 10px 20px 8px!important;
		}
		.nav--btn {
			display: block;
			width: 35px;
			height: 30px;
			position: absolute;
			right: 15px;
			top: 45px;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			z-index: 1;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #fff;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
		.nav {
			display: block;
			padding: 42px 0 0 0;
			> ul {
				display: none;
				margin-top: 10px;
				li {
					display: block;
					> a {
						display: table;
						padding: 8px 10px;
					}
					&.submenu > a:after {
						display: none;
						top: 8px;
					}
					.submenu__block {
						display: none!important;
					}
					&:nth-of-type(1) {
						border-top: 1px solid #fff;
					}
				}
			}
		}
		.search {
			height: 30px;
			position: absolute;
			right: 55px;
			top: 46px;
			margin: 0;
			input[type="text"] {
				padding: 6px 10px;
			}
			button {
				height: 30px;
				&:after {
					top: 5px;
					font-size: 16px;
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		.row {
			min-height: 35px;
		}
		.logo {
			width: 95px;
			height: 68px;
			left: 0;
			right: 0;
			top: 5px;
			margin: 0 auto;
			> img {
				width: 110px;
				margin: 0 0 0 -8px;
			}
			span {
				width: 110px;
				margin-left: -7px;
			}
		}
		.phones {
			display: none;
			width: 100%;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			padding: 10px;
			background-color: rgba(255,255,255,0.9);
			margin: 0;
			z-index: 9;
			i {
				display: none;
			}
			li {
				a {
					display: table;
					font-size: 16px;
					margin: 0 auto;
				}
			}
		}
		.phones--btn, .location--btn {
			display: block;
			width: 29px;
			height: 29px;
			position: absolute;
			left: 15px;
			top: 3px;
			font-family: 'fontAwesome';
			text-align: center;
			font-size: 18px;
			padding-top: 6px;
			box-sizing: border-box;
			color: #fff;
			&:after {
				content: '\f095';
			}
		}
		.location {
			display: none;
			width: 100%;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			padding: 10px;
			background-color: rgba(255,255,255,0.9);
			margin: 0;
			z-index: 9;
			i {
				display: none;
			}
		}
		.location--btn {
			left: 50px;
			&:after {
				content: '\f041';
			}
		}
		.btn {
			margin: 2px 0px;
		}
		.search {
			top: 43px;
			right: 15px;
		}
		.nav--btn {
			right: auto;
			left: 12px;
			top: 42px;
		}
	}
	@media screen and (max-width: 768px) {
		.btn {
			font-size: 10px!important;
			padding: 9px 10px 9px!important;
			margin: 3px 0px 0px;
		}
		.search {
			display: none;
			width: 100%;
			position: fixed;
			top: 85px;
			left: 0;
			right: 0;
			border: none;
			box-sizing: border-box;
			margin: 0;
			float: none;
			border-bottom: 1px solid #3f9725;
			input[type="text"] {
				height: 100%;
			}
			button {
				height: 31px;
				box-sizing: border-box;
			}
		}
		.search--btn {
			display: block;
			font-family: fontAwesome;
		    font-size: 20px;
		    color: #fff;
		    position: absolute;
		    right: 15px;
		    top: 45px;
		    text-align: center;
		    z-index: 1;
			&:after {
			    content: '\f002';
			    display: block;
			}
		}
	}
	@media screen and (max-width: 480px) {
		.phones--btn {
			left: 10px;
		}
		.location--btn {
			left: 45px;
		}
		.btn {
			font-size: 8px!important;
			margin: 4px -5px 0px 0px;
		}
		.nav--btn {
			left: 8px;
		}
		.search--btn {
			right: 10px;
		}
	}
}
.body {
	header {
		background-color: transparent;
		border-bottom: none;
		.breadcrumbs {
			display: none;
		}
	}
	.bg {
		&:after, &:before {
			content: '';
			display: block;
			height: 200px;
			position: fixed;
			left: 0;
			right: 0;
		}
		&:before {
			height: 300px;
			top: 0;
			background: linear-gradient(to bottom, #000, transparent 100%);
		}
		&:after {
			bottom: 0;
			background: linear-gradient(to bottom, transparent, #000 80%);
		}
	}
	@media screen and (max-width: 1250px) {
		header {
			background-color: #fff;
		}
	}
}
/* header */