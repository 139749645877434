/* footer */
footer {
	background-color: #101010;
	color: #969696;
	padding: 50px 0;
	h2 {
		color: #fff;
	}
	a {
		color: #969696;
	}
	ul {
		@include ul-default;
	}
	.footer__col {
		max-width: 300px;
		min-width: 175px;
		margin-right: 35px;
		@include inline-block; 
		&:nth-of-type(1) {
			min-width: 300px;
		}
		h2 {
			position: relative;
			margin: 0 0 25px;
			&:after {
				content: '';
				display: block;
				width: 125px;
				height: 2px;
				background-color: #fff;
				position: absolute;
				right: 0;
				top: 15px;
			}
		}
		.socials {
			li {
				margin: 0 10px 0 0;
				@include inline-block;
				a {
					display: block;
					width: 48px;
					height: 48px;
					border: 4px solid #60c339;
					text-align: center;
					box-sizing: border-box;
					padding-top: 7px;
					font-size: 20px;
					color: #60c339;
					&:hover {
						color: #fff;
					}
					i.fa-vk {
						font-size: 18px;
					}
				}
			}
		}
		span {
			display: block;
			font-size: 12px;
			color: #616161;
			margin: 8px 0 10px;
		}
		p {
			margin: 0;
		}
	}
	.copy {
		font-size: 12px;
		color: #393838;
		margin: 10px 0 0 0;
		@include inline-block;
	}
	.artmedia {
		width: 222px;
		position: relative;
		padding-right: 120px;
		float: right;
		font-size: 12px;
		box-sizing: border-box;
		margin-top: 10px;
		@include inline-block;
		img {
			width: 120px;
			position: absolute;
			top: -6px;
			right: 0;
		}
		&:hover {
			color: #969696;
		}
	}
	@media screen and (max-width: 1170px) {
		.footer__col {
			width: 22%;
			padding-right: 15px;
			margin-right: 0;
			box-sizing: border-box;
		}
	}
	@media screen and (max-width: 991px) {
		padding: 20px 0;
		.footer__col {
			width: 48%;
			max-width: 100%;
		}
	}
	@media screen and (max-width: 768px) {
		.footer__col {
			display: block;
			width: 100%;
			h2 {
				display: table;
				padding-right: 150px;
				margin-bottom: 20px;
			}
		}
	}
	@media screen and (max-width: 480px) {
		.copy {
			display: block;
			text-align: center;
			margin-bottom: 10px;
			color: #989696;
		}
		.artmedia {
			display: block;
			float: none;
			margin: 0 auto;
		}
	}
}
/* footer */