/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider {
	width: 1250px;
	padding: 0 35px 0 75px;
	margin: 0 auto 0px!important;
	box-sizing: border-box;
	.slick-slide {
		min-height: 500px;
		overflow: hidden;
		position: relative;
		outline: none;
		img {
			position: absolute;
			right: 0;
			top: 0;
			display: block;
			width: auto;
			height: 100%;
			margin: 0px 15px 0px 0px;
			z-index: -1;
		}
		.slider__block {
			width: 515px;
			text-align: left;
			color: #fff;
			margin-top: 50px;
			@include inline-block;	
			h2 {
				color: #fff;
			}
			.slider__block__title {
				display: block;
				font-size: 75px;
			}
			.slider__block__list {
				width: 100%;
				padding: 20px 0px 10px;
				border-top: 1px solid #3f9725;
				border-bottom: 1px solid #3f9725;
				margin: 15px 0px;
				font-size: 0;
				ul {
					width: 50%;
					@extend .check--circle--list;
					@include inline-block;
					li span {
						top: -2px;
					}
				}
			}
			.btn {
				margin-top: 25px;
				background-color: transparent!important;
				color: #3f9725!important;
				margin-bottom: 15px!important;
				&:hover {
					color: #fff!important;
					background-color: #3f9725!important;
					opacity: 1;
				}
			}
		}
	}
	.slick-arrow {
		width: 40px;
		height: 80px;
		transition: 400ms;
		z-index: 1;
		&:before {
			display: none;
		}
		&:after {
			display: block;
			font-family: 'fontAwesome';
			font-size: 80px;
			color: #fff;
		}
		&.slick-prev {
			left: 0px;
			&:after {
				content: '\f104';
			}
		}
		&.slick-next {
			right: 0px;
			&:after {
				content: '\f105';
			}
		}
		&:hover {
			opacity: 0.5;
		}
	}
	@media screen and (max-width: 1250px) {
		width: 100%;
		padding: 0 45px;
		box-sizing: border-box;
		.slick-slide {
			min-height: 400px;
			.slider__block {
				width: 60%;
				margin-top: 0px;
			}
		}
		.slick-arrow {
			&:after {
				font-size: 60px;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.slick-slide {
			min-height: 350px;
			.slider__block {
				h2 {
					padding-bottom: 15px;
					margin-bottom: 15px;
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.slick-slide {
			min-height: 1px;
			img {
				display: none;
			}
			.slider__block {
				width: 100%;
			}
		}
		.slick-arrow {
			width: 30px;
			height: 35px;
			&.slick-prev {
				left: 0;
			}
			&.slick-next {
				right: 0;
			}
			&:after {
				font-size: 35px;
			}
		}
	}
	@media screen and (max-width: 480px) { 
		padding: 0 15px;
		.slick-arrow {
			display: none;
		}
		.slick-slide {
			.slider__block {
				h2 {
					font-size: 16px;
					padding-bottom: 5px;
					margin-bottom: 5px;
				}
				.slider__block__list {
					ul {
						display: block;
						width: 100%;
					}
				}
				.btn {
					margin-top: 10px;
				}
			}
		}
	}
}
/* main slider */

/* main cat */
.cat {
	position: relative;
	border-top: 4px solid #3f9725;
	border-bottom: 4px solid #3f9725;
	font-size: 0;
	.cat__col {
		width: 20%;
		font-family: $bold;
		font-size: 12px;
		color: #fff;
		text-transform: uppercase;
		text-align: center;
		box-sizing: border-box;
		padding: 0px 10px;
		@include inline-block;
		&:hover {
			color: #3f9725;
		}
		.cat__col__img {
			overflow: hidden;
			margin: 0 auto;
			text-align: center;
		}
		.cat__col__title {
			display: block;
			margin-top: 25px;
		}
	}
	@media screen and (max-width: 768px) {
		.cat__col {
			.cat__col__title {
				margin-top: 15px;
			}
		}
	}
	@media screen and (max-width: 620px) {
		.cat__col {
			width: 33.333%;
			margin-bottom: 15px;
		}
	}
	@media screen and (max-width: 420px) {
		.cat__col {
			width: 50%;
		}
	}
}
/* main cat */

/* main about */
.about {
	h1 {
		color: #fff;
		text-align: left;
		margin: 0 0 25px;
	}
	.about__left {
		width: calc(100% - 500px);
		@include inline-block;
	}
	.about__right {
		width: 490px;
		padding-left: 25px;
		box-sizing: border-box;
		overflow: hidden;
		@include inline-block;
		ul {
			@include ul-default;
			li {
				min-height: 88px;
				position: relative;
				padding: 0 0 25px 70px;
				box-sizing: border-box;
				.about__right__img {
					width: 49px;
					height: 45px;
					text-align: center;
					padding-top: 3px;
					box-sizing: border-box;
					position: absolute;
					top: 2px;
					left: 0;
					&:before {
						content: '';
						display: block;
						width: 0;
						height: 0;
						position: absolute;
						border-top: 5px solid #3f9725;
						border-left: 7px solid transparent;
						border-right: 7px solid transparent;
						bottom: -5px;
						left: 0;
						right: 0;
						margin: 0 auto;
					}
					&:after {
						content: '';
						display: block;
						width: 4px;
						height: 100%;
						background-color: #3f9725;
						position: absolute;
						left: 0;
						right: 0;
						top: 45px;
						margin: 0 auto;
					}
				}
				h5 {
					color: #fff;
					margin: 0 0 2px;
				}
				.btn {
				    padding: 6px 15px 4px!important;
				    font-size: 11px!important;
				    text-transform: inherit;
				}
				.download {
					display: block;
					margin-bottom: 2px;
				}
				span {
					color: #857c7c;
					margin-right: 10px;
				}
				a {
					text-decoration: underline;
					color: #fff;
					&:hover {
						color: #3f9725;
						text-decoration: none;
					}
				}
				&:last-of-type {
					.about__right__img {
						&:before, &:after {
							display: none;
						}
					}
				}
			}
		}
	}
	.about__block {
		margin: 0 -.25%;
		font-size: 0;
		.about__block__col {
			min-height: 160px;
			font-size: 15px;
			width: 12%;
			text-align: center;
			padding: 10px;
			margin: 0 .25%;
			box-sizing: border-box;
			@include inline-block;
			.about__block__col__img {
				min-height: 70px;
				overflow: hidden;
				text-align: center;
				line-height: 1;
				box-sizing: border-box;
				img {
					display: block;
					margin: 0 auto;
				}
				span {
					font-size: 75px;
					line-height: 1;
				}
			}
			.about__block__col__title {
				display: table;
				width: 100%;
				height: 60px;
				min-height: 60px;
				box-sizing: border-box;
				p {
					display: table-cell;
					min-height: 60px;
					font-family: $bold;
					font-size: 15px;
					vertical-align: middle;
					margin: 0;
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		.about__block {
			.about__block__col {
				width: 24%;
				margin: 0 .5% 10px;
			}
		}
		.about__left {
			width: calc(100% - 400px);
		}
		.about__right {
			width: 390px;
		}
	}
	@media screen and (max-width: 768px) {
		.about__block {
			.about__block__col {
				width: 48%;
				margin: 0 1% 10px;
			}
		}
		.about__left {
			display: block;
			width: 100%;
		}
		.about__right {
			display: block;
			width: 100%;
			padding-left: 0;
			ul li {
				padding: 0 0 25px 60px;
				h5 {
					font-size: 14px;
				}
			}
		}
	}
}
/* main about */

/* main gallery */
.gallery {
	padding: 0px 50px;
	margin-bottom: 0!important;
	.slick-slide {
		outline: none;
		border: 1px solid #101010;
		overflow: hidden;
		img {
			display: block;
			width: 100%;
			transition: 350ms;
		}
		&:hover {
			img {
				transform: scale(1.1);
			}
		}
	}
	.slick-arrow {
		width: 40px;
		height: 60px;
		transition: 400ms;
		&:before {
			display: none;
		}
		&:after {
			display: block;
			font-family: 'fontAwesome';
			font-size: 60px;
			color: #fff;
		}
		&.slick-prev {
			left: 5px;
			&:after {
				content: '\f104';
			}
		}
		&.slick-next {
			right: 5px;
			&:after {
				content: '\f105';
			}
		}
		&:hover {
			opacity: 0.5;
		}
	}
	@media screen and (max-width: 991px) {
		.slick-arrow {
			width: 40px;
			height: 40px;
			&:after {
				font-size: 40px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.slick-arrow {
			width: 30px;
			height: 30px;
			&.slick-prev {
				left: 10px;
			}
			&.slick-next {
				right: 10px;
			}
			&:after {
				font-size: 30px;
			}
		}
	}
	@media screen and (max-width: 480px) {
		padding: 0 15px;
		.slick-arrow {
			z-index: 1;
		}
	}
}
/* main gallery */

/* main order project */
.order--project {
	display: table;
	width: 100%;
	background-color: #46a029;
	font-size: 0;
	.order--project__col {
		display: table-cell;
		height: 100%;
		width: 50%;
		position: relative;
		font-size: 14px;
		vertical-align: middle;
		padding: 15px 0px;
		box-sizing: border-box;
		&.left:after {
			content: '';
			width: 0;
			height: 0;
			position: absolute;
			border-top: 15px solid transparent;
			border-bottom: 15px solid transparent;
			border-left: 15px solid #46a029;
			top: 50%;
			right: -15px;
			margin-top: -15px;
			z-index: 1;
		}
		&.right {
			padding-left: 160px;
			background-color: #36871c;
			> .btn {
				background-color: transparent!important;
				border: 2px solid #ffffff;
				padding: 12px 60px 10px!important;
				text-decoration: none!important;
				color: #fff;
				opacity: 1;
				&:hover {
					background-color: #fff!important;
					color: #36871c!important;
				}
			}
		}
		.order--project__col__block {
			width: 525px;
			padding-right: 100px;
			float: right;
			h4 {
				margin: 15px 0;
				color: #fff;
			}
			p {
				color: #b1fa9a;
			}
		}
	}
	@media screen and (max-width: 1250px) {
		.order--project__col {
			.order--project__col__block {
				padding: 0 15px;
				width: 100%;
				box-sizing: border-box;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.order--project__col { 
			&.right {
				padding: 0 15px 0 35px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.order--project__col {
			&.right > .btn {
				padding: 9px 20px 6px !important;
			}
			&.left {
				.order--project__col__block {
					padding-left: 15px;
				}
			}
			.order--project__col__block {
				padding: 0px;
			}
		}
	}
	@media screen and (max-width: 580px) {
		.order--project__col {
			display: block;
			padding: 15px!important;
			&.right, &.left {
				display: block;
				width: 100%;
				&:after {
					display: none;
				}
				> .btn {
					margin: 0 auto!important;
				}
			}
			&.left {
				.order--project__col__block {
					padding-left: 0px;
				}
			}
			.order--project__col__block {
				float: none;
				h4 {
					margin: 0 0 10px 0;
				}
				p {
					margin-top: 0px;
				}
			}
		}
	}

}
/* main order project */

/* main map */
.map {
	height: 400px;
	position: relative;
	overflow: hidden;
	.map__block {
		width: 50%;
		position: absolute;
		top: 0;
		left: 0%;
		bottom: 0;
		background: url(../img/bg1.png) repeat left top;
		color: #fff;
		padding: 35px;
		box-sizing: border-box;
		margin-left: -240px;
		transition: 400ms;
		z-index: 1;
		.map__block__info {
			width: 350px;
			float: right;
			.map__block__info__img {
				overflow: hidden;
				img {
					display: block;
					width: 100%;
				}
			}
			h4 {
				color: #fff;
				margin: 10px 0;
			}
			ul {
				@include ul-default;
				li {
					display: block;
					margin-bottom: 10px;
					span {
						display: block;
						font-size: 12px;
						color: #c1cbe2;
						margin-bottom: 5px;
					}
					a {
						display: block;
						color: #fff;
					}
					p {
						margin: 0;
					}
				}
			}
		}
		.close {
			display: block;
			width: 35px;
			height: 35px;
			background-color: #101010;
			position: absolute;
			top: 30px;
			right: -35px;
			&:before, &:after {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				top: 17px;
				margin: 0 auto;
				width: 25px;
				height: 2px;
				background-color: #fff;
			}
			&:before {
				transform: rotate(45deg);
			}
			&:after {
				transform: rotate(-45deg);
			}
			&:hover {
				background-color: #46a029;
			}
			&.active {
				&:after {
					opacity: 0;
				}
				&:before {
					content: '\f054';
					display: block;
					font-family: 'fontAwesome';
					font-size: 20px;
					color: #fff;
					text-align: center;
					left: 0;
					right: 0;
					top: 8px;
					border: none;
					background-color: transparent;
					transform: rotate(0deg);
				}
			}
		}
		&.hide {
			left: -50%;
			margin-left: 0;
		}
	}
	.map__city {
		position: absolute;
		left: 42%;
		top: 25px;
		z-index: 1;
		ul {
			@include ul-default;
			li {
				margin-right: 10px;
				@include inline-block;
				a {
					display: block;
					font-family: $bold;
					font-size: 14px;
					text-transform: uppercase;
					padding: 9px 30px 7px;
					background-color: #101010;
					color: #fff;
					&:hover {
						background-color: #46a029;
					}
				}
				&.active a {
					background-color: #46a029;
				}
			}
		}
	}
	@media screen and (max-width: 1250px) {
		.map__block {
			width: 390px;
			padding: 20px;
			margin-left: 0px;
			.close {
				top: 15px;
			}
			&.hide {
				left: -390px;
			}
		}
	}
	@media screen and (max-width: 991px) {
		height: auto;
		.map__city {
			position: relative;
			left: 0;
			top: 0;
			padding: 5px 10px;
			ul li {
				margin-right: 2px;
				a {
					padding: 6px 10px 5px;
					font-size: 12px;
				}
			}
		}
		.map__block {
			top: 38px;
			padding: 10px 15px;
			background-image: none;
			background-color: rgba(0,0,0,0.7);
			.close {
				top: 0;
			}
			.map__block__info__img {
				display: none;
			}
			.map__block__info {
				float: none;
				width: 100%;
			}
		}
		> ymaps {
			height: 250px!important;
		}
	}
	@media screen and (max-width: 480px) {
		.map__block {
			width: 100%;
			padding-right: 25px;
			position: relative;
			top: 0;
			&.hide {
				left: 0;
			}
			.map__block__info {
				h4 {
					font-size: 14px;
					margin-top: 0px;
				}
				ul li {
					span {
						font-size: 10px;
					}
					p {
						font-size: 8px;
					}
				}
			}
			.close {
				display: none;
				right: 0;
				width: 25px;
				height: 25px;
				&:after, &:before {
					width: 18px;
					top: 12px;	
				}
			}
		}
	}
}
/* main map */

.btn--up {
	position: fixed;
	right: 50%;
	bottom: 75px;
	width: 65px!important;
	height: 65px!important;
	padding: 15px 0 0 0!important;
	font-size: 30px!important;
	color: #fff!important;
	text-align: center;
	box-sizing: border-box;
	margin-right: -700px;
	opacity: 0;
	z-index: 1;
	@media screen and (max-width: 1440px) {
		margin-right: 0;
		right: 15px;
	}
	@media screen and (max-width: 1280px) {
		display: none;
	}
}

/* main content */