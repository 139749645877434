/* page content */
.page {
	background-color: #fff;
	margin-top: 200px;
	.news1, .news {
		.date {
			color: #15193d;
		}
		a {
			text-decoration: underline;
			&:hover {
				color: #3f9725;
				text-decoration: none;
			}
		}
	}
	.page_nav {
		.active {
			color: #3f9725;
		}
	}
	a {
		color: #282727;
		text-decoration: underline;
		transition: 300ms;
		&:active, &:focus {
			text-decoration: none;
		}
		&:hover {
			color: #3f9725;
			text-decoration: none;
		}
	}
	h1 {
		font-size: 28px;
		margin-top: 35px;
	}
	.order--project {
		margin-top: 100px;
	}
	.scroll {
		width: 100%;
		overflow: auto;
	}
	@media screen and (max-width: 1250px) {
		margin-top: 111px;
		h1 {
			margin-top: 15px;
		}
		.order--project {
			margin-top: 75px;
		}
	}
	@media screen and (max-width: 1170px) {
		margin-top: 88px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
	@media screen and (max-width: 991px) {
		margin-top: 85px;
		.order--project {
			margin-top: 50px;
		}
		h1 {
			font-size: 26px;
		}
	}
	@media screen and (max-width: 768px) {
		.order--project {
			margin-top: 25px;
		}
		h1 {
			font-size: 22px;
		}
	}
	@media screen and (max-width: 480px) {
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
	min-height: 45px;
	position: relative;
	font-size: 12px;
	padding: 10px 0px 10px 30px;
	box-sizing: border-box;
	&:after {
		content: '';
		background-image: url(../img/home.png);
		background-repeat: no-repeat;
		background-position: center center;
		background-color: #3f9725;
		display: block;
		width: 18px;
		height: 18px;
		position: absolute;
		left: 0;
		top: 13px;
	}
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #5e5e5e;
			@include inline-block;
			a {
				color: #55b431;
				font-size: 12px;
				text-decoration: none;
			}
			&:after {
				content: '';
				width: 17px;
				height: 7px;
				background: url(../img/bread-arrow.png) no-repeat center center;
				margin: 5px 5px 0px 10px;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
	@media screen and (max-width: 1250px) {
		padding: 10px 15px 10px 45px;
		&:after {
			left: 15px;
		}
	}
}
/* breadcrumbs */

/* page about */
.page--about--left {
	width: 850px;
	@include inline-block;
	.page--about--left__block {
		.check--circle--list li {
			width: 30%;
			box-sizing: border-box;
			margin-right: 2%;
			@include inline-block;
			span {
				top: 5px;
			}
		}
	}
	@media screen and (max-width: 1250px) {
		width: calc(100% - 370px);
	}
	@media screen and (max-width: 1170px) {
		.page--about--left__block {
			.check--circle--list li {
				width: 49%;
				margin-right: 0;
			}
		}
	}
	@media screen and (max-width: 768px) {
		display: block;
		width: 100%;
		.page--about--left__block {
			.check--circle--list li {
				width: 100%;
				display: block;
				margin-right: 0;
			}
		}
	}
}
.page--about--right {
	width: 350px;
	float: right;
	@include inline-block;
	@media screen and (max-width: 768px) {
		display: block;
		width: 100%;
		float: none;
	}
	@media screen and (max-width: 480px) {
		.g-recaptcha {
			transform: scale(0.8);
			transform-origin: 0 center;
			> div {
				margin-bottom: 0px;
			}
		}
		
	}
}

/* page about slider */
.page--slider {
	border: 1px solid #b2b2b2;
	padding: 4px;
	margin-bottom: 75px!important;
	.slick-slide {
		img {
			display: block;
			width: 100%;
		}
	}
	.slick-dots {
		li {
			width: 12px;
			height: 12px;
			background-color: #c8c8c8;
			padding: 2px;
			margin: 0px 2px;
			box-sizing: border-box;
			button {
				background-color: #c8c8c8;
				padding: 0;
				width: 100%;
				height: 100%;
				transition: 350ms;
				&:before {
					display: none;
				}
				&:hover {
					background-color: #3f9725;
				}
			}
			&.slick-active {
				button {
					background-color: #3f9725;
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		margin-bottom: 50px!important;
	}
}
/* page about slider */

/* page about form */
.page--form {
	position: relative;
	padding: 35px 25px 30px 25px;
	border-left: 1px solid #b2b2b2;
	border-right: 1px solid #b2b2b2;
	border-bottom: 1px solid #b2b2b2;
	margin: 25px 0;
	&:before, &:after {
		content: '';
		display: block;
		width:15px;
		height: 1px;
		background-color: #b2b2b2;
		position: absolute;
		left: 0;
		top: 0;
	}
	&:after {
		width: 125px;
		left: auto;
		right: 0;
	}
	h4 {
		position: absolute;
		left: 25px;
		top: -10px;
		text-transform: inherit;
		margin: 0;
	}
	input[type="text"], input[type="email"], textarea {
		display: block;
		width: 100%;
		border-top: 2px solid #a6a6a6;
		border-right: 1px solid #a6a6a6;
		border-bottom: 1px solid #a6a6a6;
		border-left: 3px solid #a6a6a6;
		box-sizing: border-box;
		margin-bottom: 10px;
	}
	.btn {
		margin-top: 20px;
	}
	ul {
		@include ul-default;
		li {
			position: relative;
			padding: 0 10px 0px 20px;
			border-top: 1px solid #ebe8e8;
			&:after {
				content: '';
				display: block;
				width: 6px;
				height: 6px;
				position: absolute;
				background-color: #bcbcbc;
				left: 5px;
				top: 8px;
			}
			&:last-of-type {
				border-bottom: 1px solid #ebe8e8;
			}
			a {
				text-transform: uppercase;
				display: block;
				padding: 5px 0px;
				text-decoration: none;
				font-size: 11px;
			}
			&.active > a {
				color: #5ebf37;
			}
		}
	}
	@media screen and (max-width: 768px) {
		padding: 25px 15px 20px 15px;
	}
}
/* page about form */

/* page about */

/* page cat */
.page--cat {
	margin: 0 -15px;
	.page--cat__col {
		width: 33%;
		box-sizing: border-box;
		padding: 0 15px;
		margin-bottom: 25px;
		@include inline-block;
		.page--cat__col__img {
			display: block;
			overflow: hidden;
			margin-bottom: 25px;
			position: relative;
			img {
				display: block;
				width: 100%;
			}
			.page--cat__col__img__icon {
				display: block;
				width: 84px;
				height: 84px;
				position: absolute;
				left: 0;
				right: 0;
				margin: 0 auto;
				top: 50%;
				margin-top: -42px;
				opacity: 0.5;
				img {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					width: 100%;
					transition: 400ms;
					&.hide {
						opacity: 0;
					}
				}
			}
		}
		.page--cat__col__btn {
			display: block;
			position: relative;
			font-family: $bold;
			font-size: 18px;
			text-transform: uppercase;
			color: #272727;
			padding-right: 40px;
			margin-bottom: 10px;
			cursor: pointer;
			&:after {
				content: '\f107';
				display: block;
				font-family: 'fontAwesome';
				font-size: 30px;
				color: #272727;
				position: absolute;
				top: -6px;
				right: 10px;
				transition: 400ms;	
			}
			&.open:after {
				transform: rotate(-180deg);	
			}
		}
		.page--cat__col__list {
			display: none;
			font-size: 11px;
			@include ul-default;
			li {
				position: relative;
				padding: 0 10px 0px 20px;
				border-top: 1px solid #ebe8e8;
				&:after {
					content: '';
					display: block;
					width: 6px;
					height: 6px;
					position: absolute;
					background-color: #bcbcbc;
					left: 5px;
					top: 8px;
				}
				&:last-of-type {
					border-bottom: 1px solid #ebe8e8;
				}
				a {
					text-transform: uppercase;
					display: block;
					padding: 5px 0px;
					text-decoration: none;
					font-size: 11px;
				}
				&.active > a {
					color: #5ebf37;
				}
			}
		}
		&:last-child {
			.page--cat__col__btn {
				display: none;
			}
		}
		&:hover .page--cat__col__img .page--cat__col__img__icon img {
			transform: scale(0.8);
			&.vis {
				opacity: 0;
			}
			&.hide {
				opacity: 1;
			}
		}
	}
	@media screen and (max-width: 991px) {
		font-size: 0;
		.page--cat__col {
			width: 50%;
			font-size: 12px;
			.page--cat__col__btn {
				font-size: 16px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.page--cat__col .page--cat__col__btn {
			font-size: 15px;
		}
	}
	@media screen and (max-width: 480px) {
		.page--cat__col {
			width: 100%;
			font-size: 12px;
		}
	}
}
/* page cat */

/* page cat inside */
.cat--inside {
	.cat--inside__left {
		width: 850px;
		@include inline-block;
		.row {
			margin: 0 -48px;
		}
		.cat--inside__left__col {
			display: block;
			width: 215px;
			text-decoration: none;
			margin: 0 48px 25px;
			@include inline-block;
			.cat--inside__left__col__img {
				overflow: hidden;
				img {
					transition: 400ms;
				}
			}
			ul {
				@include ul-default;
				margin: 0px 0px 5px;
				li {
					margin-bottom: 2px;
				}
			}
			.cat--inside__left__col__title {
				display: block;
				min-height: 35px;
				font-family: $bold;
				font-size: 12px;
				margin: 15px 0px;
				transition: 300ms;
			}
			.price {
				display: block;
				font-family: $bold;
				margin: 15px 0px;
				span {
					font-size: 18px;
				}
			}
			.btn {
				padding: 12px 15px 8px!important;
			}
			&:hover {
				color: #272727;
				.cat--inside__left__col__img img {
					transform: scale(1.05);
				}
				.cat--inside__left__col__title {
					color: #3f9725;
				}
			}
		}
		.cat--inside__left__about {
			.cat--inside__left__about__panel {
				border-bottom: 2px solid #3f9725;
				ul {
					@include ul-default;
					li {
						margin-bottom: 1px;
						@include inline-block;
						a {
							display: block;
							background-color: #e4eae2;
							font-family: $bold;
							font-size: 16px;
							text-decoration: none;
							padding: 12px 20px 8px;
							line-height: 1;
							&:hover {
								background-color: #3f9725;
								color: #fff;
							}
						}
						&.active a {
							background-color: #3f9725;
							color: #fff;
						}
					}
				}
			}
		}
	}
	.cat--inside__right {
		width: 350px;
		float: right;
		padding-top: 40px;
		@include inline-block;
		.cat--inside__right__box {
			border: 1px solid #b2b2b2;
			padding: 10px;
			.btn {
				font-size: 18px!important;
				color: #fff;
				text-decoration: none;
				text-transform: initial;
				text-align: center;
				padding: 15px 20px!important;
			}
		}
		.cat--inside__right__list {
			@extend .page--form;
			ul li {
				&:first-of-type {
					border-top: none;
				}
				&:last-of-type {
					border-bottom: 1px solid transparent;
				}
			}
		}
	}
	@media screen and (max-width: 1250px) {
		.cat--inside__left {
			width: calc(100% - 400px);
		}
	}
	@media screen and (max-width: 991px) {
		.cat--inside__left {
			width: 100%;
			display: block;
			.row {
				margin: 0;
			}
			.cat--inside__left__col {
				margin: 0 15px 15px 0px;
			}
		}
		.cat--inside__right {
			float: none;
		}
	}
	@media screen and (max-width: 620px) {
		.cat--inside__right {
			width: 100%;
			.btn {
				margin: 0 auto;
				width: 100%!important;
			}
		}
	}
	@media screen and (max-width: 480px) {
		.cat--inside__left {
			.cat--inside__left__col {
				display: block;
				width: 100%;
				margin-right: 0;
				.cat--inside__left__col__img {
					width: 100%;
					img {
						width: 100%;
					}
				}
				.cat--inside__left__col__title {
					min-height: 1px;
				}
			}
			.cat--inside__left__about .cat--inside__left__about__panel {
				ul li {
					display: block;
				}
			}
		}
	}
}
/* page cat inside */

/* page good */
.good--left {
	width: 440px;
	@include inline-block;
	.slider--for {
		.slick-slide {
			outline: none;
		}
	}
	.slider--nav {
		padding: 20px 20px;
		.slick-slide {
			outline: none;
			margin: 0 10px;
			border: 2px solid transparent;
			transition: 300ms;
			cursor: pointer;
			img {
				display: block;
				width: 100%;
			}
			&:hover {
				border: 2px solid #3f9725;
			}
			&.slick-current {
				border: 2px solid #3f9725;
			}
		}
		.slick-arrow {
			width: 25px;
			height: 35px;
			transition: 300ms;
			&:before {
				display: none;
			}
			&:after {
				display: block;
				font-family: 'fontAwesome';
				font-size: 35px;
				color: #3f9725;
			}
			&.slick-prev {
				left: -5px;
				&:after {
					content: '\f104';
				}
			}
			&.slick-next {
				right: -5px;
				&:after {
					content: '\f105';
				}
			}
			&:hover {
				opacity: 0.7;
			}
		}
	}
	@media screen and (max-width: 991px) {
		width: 300px;
		.slider--for {
			img {
				display: block;
				width: 100%;
			}
		}
	}
	@media screen and (max-width: 620px) {
		display: block;
		width: 100%;
		margin: 0;
	}
}
.good--right {
	width: 750px;
	margin-left: 50px;
	@include inline-block;
	&.ral {
		margin-left: 0;
		width: 100%;
	}
	p {
		margin-top: 0;
	}
	.good--right__color {
		@include ul-default;
		li {
			padding: 2px;
			border: 1px solid transparent;
			transition: 300ms;
			cursor: pointer;
			margin: 0px 25px 10px 5px;
			@include inline-block;
			.box {
				display: block;
				width: 38px;
				height: 38px;
				padding: 2px;
				border: 1px solid transparent;
				margin: 0 auto;
				box-sizing: border-box;
				transition: 300ms;
				a {
					display: block;
					width: 100%;
					height: 100%;
				}
			}
			.title {
				display: block;
				font-size: 11px;
				margin-top: 5px;
			}
			&.active, &:hover {
				.box {
					border: 1px solid #7f7f7f;
				}
			}
		}
	}
	.good--right--status {
		display: block;
		margin: 15px 0px;
		span {
			@include inline-block;
		}
		.green {
			position: relative;
			color: #3f9725;
			&:after {
				content: '';
				height: 13px;
				width: 2px;
				background-color: #767676;
				margin: 1px 2px 0px 7px;
				@include inline-block;
			}
		}
	}
	.good--right--block {
		min-height: 72px;
		padding: 10px 20px;
		border: 1px solid #767676;
		box-sizing: border-box;
		.price {
			font-family: $bold;
			font-size: 30px;
			margin-top: 10px;
			line-height: 1;
			@include inline-block;
			span {
				font-size: 18px;
			}
		}
		.btn {
			font-size: 20px!important;
			float: right;
			padding: 12px 20px 12px!important;
			text-transform: inherit;
			@include inline-block;
		}
	}
	@media screen and (max-width: 1250px) {
		width: calc(100% - 500px);
		margin-left: 25px;
	}
	@media screen and (max-width: 991px) {
		width: calc(100% - 350px);
		.good--right--block {
			.price {
				margin-bottom: 10px;
			}
			.btn {
				display: table;
				float: none;
				width: auto!important;
				font-size: 18px!important;
			}
		}
		.good--right__color {
			li {
				margin-right: 5px;
			}
		}
	}
	@media screen and (max-width: 620px) {
		display: block;
		width: 100%;
		margin: 0;
		.good--right--block {
			padding: 10px;
		}
	}
}

.good--about {
	margin-top: 35px;
	.good--about__panel {
		border-bottom: 2px solid #3f9725;
		@include ul-default;
		li {
			margin-bottom: 1px;
			@include inline-block;
			a {
				display: block;
				background-color: #e4eae2;
				font-family: $bold;
				font-size: 16px;
				text-decoration: none;
				padding: 12px 20px 8px;
				line-height: 1;
				&:hover {
					background-color: #3f9725;
					color: #fff;
				}
			}
			&.active a {
				background-color: #3f9725;
				color: #fff;
			}
		}
	}
	.good--about__block {
		margin-top: 15px;
	}
	@media screen and (max-width: 480px) {
		.good--about__panel li {
			display: block;
		}
	}
}

.good--similar {
	padding-top: 35px;
	border-top: 1px solid #cfcfcf;
	margin-top: 50px;
	.good--similar__slider {
		padding: 0 25px;
		.slick-slide {
			outline: none;
			text-decoration: none;
			padding: 15px 35px;
			img {
				display: block;
				width: 100%;
			}
			ul {
				@include ul-default;
				margin: 0px 0px 5px;
				li {
					margin-bottom: 2px;
				}
			}
			.good--similar__slider__title {
				display: block;
				font-family: $bold;
				font-size: 12px;
				margin: 15px 0px 10px;
				transition: 300ms;
			}
			.green {
				color: #3f9725;
				font-size: 12px;
			}
			.price {
				display: block;
				font-family: $bold;
				margin: 15px 0px;
				font-size: 18px;
				span {
					font-size: 12px;
				}
			}
			.btn {
				padding: 12px 25px 10px!important;
			}
			&:hover {
				color: #272727;
				.good--similar__slider__title {
					color: #3f9725;
				}
			}
		}
		.slick-arrow {
			width: 40px;
			height: 60px;
			transition: 300ms;
			top: 40%;
			&:before {
				display: none;
			}
			&:after {
				display: block;
				font-family: 'fontAwesome';
				font-size: 60px;
				color: #3f9725;
			}
			&.slick-prev {
				left: -5px;
				&:after {
					content: '\f104';
				}
			}
			&.slick-next {
				right: -5px;
				&:after {
					content: '\f105';
				}
			}
			&:hover {
				opacity: 0.7;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.good--similar__slider {
			.slick-slide {
				padding: 15px;
			}
		}
	}
	@media screen and (max-width: 480px) {
		.good--similar__slider {
			padding: 0;
			.slick-slide {
				padding: 0px;
			}
			.slick-arrow {
				width: 25px;
				height: 35px;
				top: 40%;
				z-index: 1;
				&:after {
					font-size: 35px;
				}
				&.slick-prev {
					left: 0;
				}
				&.slick-next {
					right: 0;
				}
			}
		}
	}
}
/* page good */

/* page services */
.services {
	.services__block {
		position: relative;
		padding: 30px 420px 20px 80px;
		border-bottom: 1px solid #d7d7d9;
		min-height: 120px;
		.services__block__img {
			position: absolute;
			left: 0;
			top: 20px;
			overflow: hidden;
		}
		h5 {
			margin: 0 0 20px;
		}
		.check--list {
			max-width: 230px;
			margin: 15px 35px 15px 0;
			@include inline-block;
			li:before {
				color: #797b81;
			}
		}
		.services__block__btn {
			position: absolute;
			right: 0;
			top: 30px;
			width: 350px;
			padding: 20px;
			border: 1px solid #b2b2b2;
			box-sizing: border-box;
			.btn {
				font-size: 18px!important;
				width: 100%!important;
				padding: 15px 20px!important;
				text-transform: inherit;
			}
		}
		&:last-of-type {
			border-bottom: none;
		}
	}
	@media screen and (max-width: 991px) {
		.services__block {
			padding-right: 265px;
			.services__block__btn {
				width: 250px;
				padding: 15px;
				.btn {
					font-size: 14px!important;
					padding: 10px 10px!important
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.services__block {
			padding: 15px 0px 15px 40px;
			.services__block__img {
				width: 30px;
				top: 16px;
				img {
					display: block;
					width: 100%;
				}
			}
			.services__block__btn {
				position: relative;
				right: 0;
				top: 0;
			}
		} 
	}
}
/* page services */

/* page articles */
.articles {
	padding: 35px 0px;
	border-bottom: 1px solid #dcdedb;
	.articles__col {
		width: 390px;
		text-decoration: none;
		@include inline-block;
		.articles__col__img {
			img {
				display: block;
				width: 100%;
			}
		}
		&:nth-of-type(even) {
			margin-left: 60px;
		}
		h5 {
			margin-bottom: 10px;
			transition: 300ms;
		}
		.date {
			display: block;
			color: #6e7076;
			margin-bottom: 15px;
		}
		&:hover {
			h5 {
				color: #3f9725;
			}
		}
	}
	@media screen and (max-width: 1250px) {
		.articles__col {
			width: 48%;
			&:nth-of-type(even) {
				margin-left: 3%;
			}
		}
	}
	@media screen and (max-width: 480px) {
		padding: 15px 0px;
		.articles__col {
			width: 100%;
			margin-bottom: 15px;
			&:nth-of-type(even) {
				margin-left: 0;
			}
		}
	}
}

.articles--date {
	display: table;
	width: 210px;
	padding: 10px 0px;
	border-bottom: 2px solid #60c339;
}

.pagination {
	padding: 20px 0px;
	text-align: center;
	.prev, .next {
		font-family: $bold;
		font-size: 12px;
		text-transform: uppercase;
		color: #6e7076;
		text-decoration: none;
		margin-top: 5px;
		position: relative;
		@include inline-block;
		&:after {
			content: '';
			display: block;
			width: 40px;
			height: 2px;
			background-color: #6e7076;
			position: absolute;
			top: 50%;
			margin-top: -2px;
			transition: 300ms;
		}
		&:hover {
			&:after {
				background-color: #3f9725;
			}
		}
	} 
	.prev {
		float: left;
		padding-left: 55px;
		&:after {
			left: 0;
		}
	}
	.next {
		float: right;
		padding-right: 55px;
		&:after {
			right: 0;
		}
	}
	ul {
		@include ul-default;
		@include inline-block;
		li {
			margin: 4px 8px;
			@include inline-block;
			a {
				display: block;
				font-family: $bold;
				font-size: 18px;
				text-decoration: none;
				color: #46a029;
				line-height: 1;
				&:hover {
					color: #6e7076;
				}
			}
			&.active a {
				color: #6e7076;
			}
		}
	}
	@media screen and (max-width: 768px) {
		padding: 10px 0px;
		.prev, .next {
			display: table;
			padding: 0;
			float: none;
			margin: 10px auto;
			&:after {
				display: none;
			}
		}
	}
}
/* page articles */

/* page gallery */
.photo--gallery {
	font-size: 0;
	.gallery--col {
		width: 395px;
		background-color: #f0f3f5;
		padding: 35px;
		box-sizing: border-box;
		font-size: 12px;
		margin: 0 32px 32px 0px;
		border-bottom: 2px solid #dce1e4;
		text-decoration: none;
		@include inline-block;
		a {
			outline: none;
			text-decoration: none;
		}
		h5 {
			text-transform: uppercase;
			margin-top: 0;
		}
		.gallery--col__about {
			font-size: 12px;
			border-top: 1px solid #dadde6;
			border-bottom: 1px solid #dadde6;
			margin-bottom: 15px;
			color: #6e7076;
			ul {
				@include ul-default;
				li {
					padding: 0px 12px;
					margin: 10px 0;
					position: relative;
					@include inline-block;
					&:first-of-type {
						padding-left: 0;
					}
					&:last-of-type {
						padding-right: 0;
						&:after {
							display: none;
						}
					}
					&:after {
						content: '';
						display: block;
						width: 1px;
						height: 100%;
						background-color: #dadde6;
						position: absolute;
						right: -1px;
						top: 0;
					}
				}
			}
		}
		.gallery--col__block {
			font-size: 0;
			margin-top: 50px;
			div, a {
				width: 25%;
				border-right: 1px solid transparent;
				box-sizing: border-box;
				overflow: hidden;
				@include inline-block;
				img {
					display: block;
					width: 100%;
				}
			}
		}
		.zoom {
			display: block;
			width: 52px;
			height: 40px;
			border-top: 4px solid #5cbe36;
			border-bottom: 4px solid #5cbe36;
			margin: 25px auto 0px;
			color: #313131;
			text-align: center;
			&:after {
				content: '\f00e';
				display: block;
				font-family: 'fontAwesome';
				font-size: 30px;
				margin-top: 4px;
			}
		}
		&:nth-of-type(3n-3) {
			margin-right: 0px;
		}
	}
	@media screen and (max-width: 1250px) {
		.gallery--col {
			width: 31%;
		}
	}
	@media screen and (max-width: 1170px) {
		.gallery--col {
			padding: 15px;
		}
	}
	@media screen and (max-width: 991px) {
		.gallery--col {
			width: 48%;
			margin-right: 4%;	
			&:nth-of-type(2n-2) {
				margin-right: 0%;
			}
			&:nth-of-type(3n-3) {
				margin-right: 4%;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.gallery--col {
			width: 100%;
			margin: 0 0 15px 0;
			&:nth-of-type(2n-2) {
				margin-right: 0%;
			}
			&:nth-of-type(3n-3) {
				margin-right: 0%;
			}
			.gallery--col__about {
				ul li {
					padding: 0 6px;
				}
			}
		}
	}
	@media screen and (max-width: 480px) {
		.gallery--col .gallery--col__about ul li {
			display: block;
			border: none;
			padding: 0 5px!important;
			&:after {
				display: none;
			}
		}
	}
}
/* page gallery */

/* page contacts */
.contacts {
	.contacts__panel {
		border-bottom: 2px solid #60c339;
		ul {
			@include ul-default;
			li {
				@include inline-block;
				a {
					display: block;
					background-color: #60c339;
					font-family: $bold;
					font-size: 15px;
					color: #fff;
					padding: 10px 35px;
					text-align: center;
					text-decoration: none;
				}
			}
		}
	}
	.contacts__address {
		padding-top: 45px;
		.contacts__address__col {
			min-height: 35px;
			position: relative;
			padding: 5px 10px 5px 60px;
			margin-bottom: 15px;
			@include inline-block;
			i {
				width: 43px;
				height: 43px;
				position: absolute;
				top: 0;
				left: 0;
				background-color: #60c339;
				color: #fff;
				font-size: 25px;
				&:before {
					position: absolute;
					left: 0;
					right: 0;
					top: 9px;
					text-align: center;
				}
				&.fa-phone {
					font-size: 22px;
					&:before {
						top: 10px;
					}
				}
				&.fa-envelope-o {
					font-size: 22px;
					&:before {
						top: 9px;
					}
				}
			}
			&.col--location {
				width: 280px;
			}
			&.col--phones {
				width: 320px;
			}
			&.col--email {
				a {
					color: #3f9725;
					text-decoration: underline;
					&:hover {
						text-decoration: none;
					}
				}
			}
			ul {
				@include ul-default;
				@include inline-block;
				margin-right: 15px;
				li {
					margin-bottom: 5px;
				}
			}
			a {
				text-decoration: none;
			}
		}
	}
	.map {
		margin-top: 65px;
	}
	@media screen and (max-width: 768px) {
		.contacts__address {
			padding-top: 15px;
		}
		.map {
			margin-top: 25px;
		}
	}
	@media screen and (max-width: 480px) {
		.contacts__address {
			padding-top: 15px;
			.contacts__address__col {
				width: 100%!important;
				padding: 0px 10px 0px 40px;
				box-sizing: border-box;
				i {
					width: 30px;
					height: 30px;
					font-size: 18px;
					&.fa-envelope-o {
						font-size: 16px;
						&:before {
							top: 6px;
						}
					}
					&.fa-phone {
						font-size: 18px;
						&:before {
							top: 7px;
						}
					}
					&:before {
						top: 7px;
					}
				}
			}
		}
	}
}
/* page contacts */

/* modal */
.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	.modal__block {
		position: absolute;
		width: 480px;
		padding: 15px;
		left: 0;
		right: 0;
		top: 75px;
		margin: 0 auto;
		background-color: #fff;
		z-index: 9999;
		box-sizing: border-box;
		h3 {
			margin: 0;
		}
		form {
			margin-top: 15px;
			input[type="text"],
			input[type="email"],
			textarea {
				width: 100%;
				padding: 8px 15px;
				box-sizing: border-box;
				border: 1px solid #3f9725;
				margin-bottom: 15px;
				font-family: $regular;
				font-size: 14px;
			}
			.btn {
				float: right;
			}
		}
		.close {
			position: absolute;
			width: 22px;
			height: 22px;
			font-family: 'fontAwesome';
			font-size: 22px;
			right: -30px;
			top: -20px;
			color: #fff;
			text-decoration: none;
			&:after {
				content: '\f00d';
				display: block;
			}
			&:hover {
				opacity: 0.7;
			}
		}
		.g-recaptcha {
			transform: scale(1);
			transform-origin: 100% 0;
			> div {
				float: right;
				margin-bottom: 15px;
			}
		}
	}
	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,0.7);
		z-index: 999;
	}
	@media screen and (max-width: 768px) {
		.modal__block {
			width: 80%;
			top: 35px;
		}
	}
	@media screen and (max-width: 480px) {
		.modal__block {
			.g-recaptcha {
				transform: scale(0.75);
				transform-origin: 100% center;
				> div {
					margin-bottom: 0px;
				}
			}
		}
	}
}

/* modal */

/* page content */